export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const INCREASE_CART_ITEM_QUANTITY = "INCREASE_CART_ITEM_QUANTITY";
export const DECREASE_CART_ITEM_QUANTITY = "DECREASE_CART_ITEM_QUANTITY";
export const SET_CART_TO_LOCAL_STORAGE = "SET_CART_TO_LOCAL_STORAGE";
export const GET_CART_FROM_LOCAL_STORAGE = "GET_CART_FROM_LOCAL_STORAGE";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const TOGGLE_CART = "OPEN_CART";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SIGN_UP = "SIGN_UP";
export const ROUTE_TO_DASHBOARD = "ROUTE_TO_DASHBOARD";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const PERSIST_USER = "PERSIST_USER";
export const AUTH_STATE_CHANGE = "AUTH_STATE_CHANGE";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const ORDER_PRODUCT = "ORDER_PRODUCT";
export const GET_PRODUCT = "GET_PRODUCT";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDER = "GET_ORDER";
export const GET_ALL_PRODUCTS = "GET_ALL_PRODUCTS";
